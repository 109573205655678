import Api from "services/Api/Class";

export default class Spark extends Api {
  constructor(opts) {
    super(opts);
    this.authClient = opts.authClient;
  }

  getIrpSchools({ signal }) {
    return this.fetch({
      url: "/school_service/irp_schools",
      signal,
    });
  }

  getIrpStudents({ q, signal }) {
    return this.fetch({
      url: "/api/students",
      params: { q },
      signal,
    });
  }
}
